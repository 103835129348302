import { Button, ButtonProps, styled } from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { theme } from "../theme/Theme";
import { FontWeight } from "../theme/Design";

interface LinkButtonProps extends ButtonProps {
  buttonText: string;
  icon?: React.ReactNode | null;
  iconPosition?: "left" | "right";
  removePadding?: boolean;
}

export const LinkButton = styled(
  ({ buttonText, icon = <AddIcon />, removePadding = false, iconPosition = "left", ...rest }: LinkButtonProps) => (
    <>
      <Button {...rest} disableRipple>
        {iconPosition === "left" && icon}
        <p>{buttonText}</p>
        {iconPosition === "right" && icon}
      </Button>
    </>
  ),
)((props: LinkButtonProps) => ({
  display: "flex",
  alignItems: "center",
  color: theme.color.primary.dark,
  padding: props.removePadding ? 0 : undefined,
  "& p": {
    margin: 0,
    marginLeft: props.iconPosition === "left" && !props.removePadding ? 5 : 0,
    marginRight: props.iconPosition === "right" && !props.removePadding ? 5 : 0,
    fontWeight: FontWeight.semibold,
  },
  "& svg": {
    fontSize: 24,
  },
}));
