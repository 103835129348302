import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Colour } from "../../theme/Design";

const useStyles = makeStyles({
  circle: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    marginRight: 8,
    marginBottom: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colour.red,
  },
  icon: {
    color: Colour.white,
    width: 12,
    height: 12,
  },
});

export const CancelIcon: React.FC = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <span className={classes.circle}>
      <CloseIcon className={classes.icon} />
    </span>
  );
};
