import { styled } from "@material-ui/core/styles";
import React from "react";
import { Colour } from "../theme/Design";

interface StatusBoxProps {
  background?: string;
  colour?: string;
  width?: string;
  addLeftSpacing?: boolean;
  addTopSpacing?: boolean;
  children: React.ReactNode;
}

export const StatusBox = styled(
  ({ background, colour, width, addLeftSpacing, addTopSpacing, children, ...HTMLProps }: StatusBoxProps) => (
    <div {...HTMLProps}>{children}</div>
  ),
)(({ background, colour, width, addLeftSpacing, addTopSpacing }: StatusBoxProps) => ({
  background: background || Colour.slate,
  color: colour || Colour.white,
  width,
  marginLeft: addLeftSpacing ? 10 : "auto",
  marginRight: "auto",
  marginTop: addTopSpacing ? 5 : "auto",
  fontSize: 14,
  fontWeight: 600,
  padding: "5px 10px",
  borderRadius: 4,
  position: "relative",
}));
