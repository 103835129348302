import Switch from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

export const SwitchBtn = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.color.primary.dark,
          borderColor: theme.color.primary.dark,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.color.gray.main}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.color.gray.main,
    },
    checked: {},
  }),
)(Switch);
