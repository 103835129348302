import React from "react";
import { styled } from "@material-ui/core";

interface IHTMLProps {}
export const PageLoader = styled(({ ...HTMLProps }: IHTMLProps) => (
  <div {...HTMLProps}>{/* <CircularProgress disableShrink /> */}</div>
))({
  textAlign: "center",
  padding: 40,
});
