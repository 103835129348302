import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { formatDate } from "./formatter";

export function formatLastAccessDate(date: Date): string {
  const now = new Date();
  let unit = "";
  let plural = false;
  let number = 0;
  const minuteDifference = differenceInMinutes(now, date);

  if (minuteDifference < 1) {
    return "Last accessed just now";
  }
  const hourDifference = Math.round(minuteDifference / 60);
  const dayDifference = Math.round(hourDifference / 24);

  if (minuteDifference < 60) {
    number = minuteDifference;
    unit = "minute";
    plural = minuteDifference > 1;
  } else if (hourDifference < 24) {
    number = hourDifference;
    unit = "hour";
    plural = hourDifference > 1;
  } else if (dayDifference < 7) {
    number = dayDifference;
    unit = "day";
    plural = dayDifference > 1;
  } else {
    return `Last accessed ${formatDate(date)}`;
  }
  return `Last accessed ${number} ${unit}${plural ? "s" : ""} ago`;
}

export const toShortFormat = (date: Date): string => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = date.getDate();

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
};

export const combineDateAndTime = (date: Date, time: Date): Date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const hours = time.getHours();
  const minutes = time.getMinutes();

  return new Date(year, month, day, hours, minutes);
};

export const calculateTimeRemaining = (date: Date | undefined): string | null => {
  if (!date) return null;

  const currentDate = new Date();

  const diffInDays = differenceInDays(date, currentDate);
  const diffInHours = differenceInHours(date, currentDate) % 24;
  const diffInMinutes = differenceInMinutes(date, currentDate) % 60;
  const diffInSeconds = differenceInSeconds(date, currentDate) % 60;

  if (diffInDays > 1) return `${diffInDays} days`;
  if (diffInDays === 1) return "1 day";
  if (diffInHours > 1) return `${diffInHours} hours`;
  if (diffInHours === 1) return "1 hour";
  if (diffInMinutes >= 1) return `${diffInMinutes + 1} minutes`;
  if (diffInSeconds > 0) return "1 minute";
  return "";
};

/*
Converts date to a string with the format "dd MMM yyyy 'at' hh:mm a (zzz) - Ex: 05 Jul 2023 at 9:00AM (AEST)".
*/
export const convertDateWithTimezone = (
  date?: Date,
  timeZone = "Australia/Sydney",
  dateTimeSeperator = "at",
): string => {
  if (!date) return "";
  const zonedDate = utcToZonedTime(date, timeZone);
  const output = format(zonedDate, `EEE dd MMM yyyy '${dateTimeSeperator}' hh:mm a (zzz)`, {
    timeZone: "Australia/Sydney",
    locale: enAU,
  });
  return output;
};
