import { styled } from "@material-ui/styles";
import React from "react";
import { Colour } from "../../theme/Design";

export const CircleCloseIcon = styled(({ ...HTMLProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_77923_62541)">
      <rect x="5" y="4" width="10" height="11" fill="white" />
      <path
        d="M17.0711 16.9289C13.1678 20.8322 6.83216 20.8322 2.92893 16.9289C-0.974298 13.0257 -0.974298 6.69002 2.92893 2.78679C6.83216 -1.11643 13.1678 -1.11643 17.0711 2.78679C20.9743 6.69002 20.9743 13.0257 17.0711 16.9289ZM12.8284 5.61522L10 8.44365L7.17157 5.61522L5.75736 7.02944L8.58579 9.85786L5.75736 12.6863L7.17157 14.1005L10 11.2721L12.8284 14.1005L14.2426 12.6863L11.4142 9.85786L14.2426 7.02944L12.8284 5.61522Z"
        fill={Colour.red}
      />
    </g>
    <defs>
      <clipPath id="clip0_77923_62541">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
))({});
