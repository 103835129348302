import { styled } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { MuiButton as Button } from "./Button";
import { Card } from "./Card";

interface ListItemProps {
  name: string;
  value?: string | ReactNode;
  buttonAction?: () => void;
  buttonText?: string;
  buttonDisabled?: boolean;
  buttonHidden?: boolean;
  rightContent?: ReactNode;
  loader?: boolean;
  testIds?: { name?: string; button?: string };
  content?: string | React.ReactNode;
}
export const ListItem = styled(
  ({
    name,
    value,
    buttonAction,
    buttonText = "Change",
    buttonDisabled,
    buttonHidden,
    loader,
    rightContent,
    testIds,
    content,
    ...HTMLProps
  }: ListItemProps) => (
    <Card {...HTMLProps}>
      <Name>
        <strong data-testid={testIds?.name}>{name}</strong> {typeof value === "string" ? <span>{value}</span> : value}
      </Name>
      <ListItemContent>{content}</ListItemContent>
      {!buttonHidden && (
        <CompanyButtonAction>
          {rightContent || (
            <Button
              size="small"
              themeType="light"
              variant="outlined"
              color="primary"
              onClick={buttonAction}
              disabled={buttonDisabled}
              loader={loader}
              data-testid={testIds?.button}
            >
              {buttonText}
            </Button>
          )}
        </CompanyButtonAction>
      )}
    </Card>
  ),
)(() => ({
  display: "grid",
  gridTemplateColumns: ".5fr .8fr .4fr",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "34px 24px",
  gridGap: 30,
  marginBottom: 24,
}));

const ListItemContent = styled("div")({
  "& strong": {
    fontSize: 14,
    fontWeight: 600,
  },
});

const Name = styled("div")({
  "& strong": {
    marginRight: 16,
    minWidth: 160,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 600,
  },
  "& > span": {
    marginRight: 20,
    whiteSpace: "nowrap",
  },
  display: "flex",
  alignItems: "baseline",
});

const CompanyButtonAction = styled("div")({
  display: "grid",
  gridGap: 22,
  flexDirection: "column",
  direction: "rtl",
  "& button": {
    fontSize: 14,
    padding: "5px 19px !important",
    minWidth: 90,
    width: 100,
  },
});
