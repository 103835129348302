import React from "react";
import { styled } from "@material-ui/core";
import { color } from "../theme/Design";

interface CardHeaderProps {
  heading: string | React.ReactNode;
  subHeading?: string | React.ReactNode;
  middleSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  className?: string;
  isCardOpen?: boolean;
  marginBottom?: number;
}

export const CardHeader = styled(
  ({ heading, subHeading, middleSection, rightSection, className, isCardOpen, ...HTMLProps }: CardHeaderProps) => (
    <div style={{ marginBottom: isCardOpen === false ? 0 : 16 }} {...HTMLProps}>
      <CardHeaderStyle className={className}>
        <h4>
          {heading}
          {subHeading && <small> {subHeading}</small>}
        </h4>
        {middleSection}
        {rightSection}
      </CardHeaderStyle>
    </div>
  ),
)((props: CardHeaderProps) => ({
  "& .MuiButton-contained": {
    padding: "6px 10px",
    minWidth: "128px",
    fontSize: 14,
  },
  "& .MuiButton-text": {
    padding: "4px 6px",
    fontSize: 14,
    minWidth: 0,
  },
  "& h4": {
    marginBottom: 0,
    "& small": {
      fontSize: 10,
      opacity: 0.5,
      marginLeft: 10,
      verticalAlign: "bottom",
    },
  },
  "&.outside-header": {
    marginBottom: props.isCardOpen ? 22 : 0,
    "& h4": {
      fontSize: 18,
    },
  },
}));

const CardHeaderStyle = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: 36,
});

interface CardProps {
  className?: string;
  cardHeader?: CardHeaderProps;
}

export const Card = styled(({ className, cardHeader, ...rest }: CardProps) => (
  <>
    {cardHeader && <CardHeader className="outside-header" {...cardHeader} />}
    <div className={`card ${className}`} {...rest} />
  </>
))(({ theme }) => ({
  padding: 24,
  marginBottom: 20,
  background: color.white,
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: 6,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "& .MuiButton-sizeSmall": {
    fontSize: 14,
  },
}));
