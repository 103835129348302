import { styled } from "@material-ui/styles";
import React from "react";

export const OpenInNewTabIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clipPath="url(#clip0_76972_5918)">
      <path
        d="M16.2222 17.2223H3.77778V4.77784H10V3.00006H3.77778C2.79111 3.00006 2 3.80006 2 4.77784V17.2223C2 18.2001 2.79111 19.0001 3.77778 19.0001H16.2222C17.2 19.0001 18 18.2001 18 17.2223V11.0001H16.2222V17.2223ZM11.7778 3.00006V4.77784H14.9689L6.23111 13.5156L7.48444 14.7689L16.2222 6.03117V9.22228H18V3.00006H11.7778Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_76972_5918">
        <rect width="20" height="20" fill="white" transform="translate(0 0.500061)" />
      </clipPath>
    </defs>
  </svg>
))({});
