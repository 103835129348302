import { styled } from "@material-ui/core";
import React from "react";
import MuiInfoIcon from "@material-ui/icons/Info";
import { Colour } from "../theme/Design";

interface HTMLProps {}

export const InfoIcon = styled(({ ...HTMLProps }: HTMLProps) => <MuiInfoIcon {...HTMLProps} />)({
  marginLeft: 5,
  fontSize: 16,
  color: Colour.grey,
});
