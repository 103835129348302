import { History } from "history";
import { IInvestorProfiles, IInvestorRequest } from "@boulevard1/mystake-common-sdk";
import { calculateTimeRemaining } from "./date-utils";
import { INavigationState, NavigationSources, PageSources } from "../../constants/constants";

export const getBlobAsUrl = (buffer: string): string => {
  const binaryString = atob(buffer);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], {
    type: "application/pdf",
  });

  return URL.createObjectURL(blob);
};

export const updateTimelineSteps = (activeStep: number, dates: (Date | undefined)[]): number => {
  if (activeStep >= dates.length) return activeStep;

  const timeRemainings = dates.map((date) => calculateTimeRemaining(date));

  if (timeRemainings[activeStep] === "") {
    return Math.min(activeStep + 1, dates.length);
  }

  return activeStep;
};

export const calculateDateInterval = (nextDate: Date): number => {
  const now = new Date();
  const timeDifference = nextDate.getTime() - now.getTime();

  const oneHour = 60 * 60 * 1000;
  const tenMinutes = 10 * 60 * 1000;
  const oneMinute = 60 * 1000;

  if (timeDifference > 24 * oneHour) return oneHour;
  if (timeDifference > oneHour) return tenMinutes;
  if (timeDifference > tenMinutes) return oneMinute;
  return 1000;
};

/**
 * Format phone number
 * @param phoneNumber 0438979911
 * @param [dialCode] +61
 *
 * @returns +61 0438979911
 */
export const formatPhoneNumber = (phoneNumber: string, dialCode?: string): string => {
  if (!dialCode) return phoneNumber;
  return `${dialCode} ${phoneNumber}`;
};

/**
 * All page sources should be grouped with App navigation sources via the fromApp base state
 */
const checkNavigationSource = (history: History): INavigationState => {
  const state = history.location.state as INavigationState;

  if (state?.base) {
    return { base: state.base, page: state.page };
  }

  if (history.action === "PUSH") {
    return { base: NavigationSources.fromApp };
  }

  // Using bookmark or manually entering URL
  return { base: NavigationSources.directAccess };
};

/**
 * The inverse result (!isFromApp) can be used to check for fromSignIn and directAccess
 */
export const checkIsFromApp = (history: History): boolean =>
  checkNavigationSource(history).base === NavigationSources.fromApp;

export const checkIsFromAdminReviewRequest = (history: History): boolean => {
  const navigationState = checkNavigationSource(history);
  return navigationState.base === NavigationSources.fromApp && navigationState.page === PageSources.adminReviewRequest;
};

/**
 * Filter investor profiles by unique companyIds
 */
export const getUniqueCompanyCount = (investorProfiles: (IInvestorProfiles | null)[]): number =>
  new Set(
    investorProfiles.flatMap((profile: IInvestorProfiles | null) =>
      profile ? profile.investors.map((request: IInvestorRequest) => request.companyId) : [],
    ),
  ).size;

/**
 * @example
 * Selected investor{getPluralSuffix(selected.length)}
 * @returns s - Selected investors
 * @returns "" - Selected investor
 */
export const getPluralSuffix = (count: number): string => (count > 1 ? "s" : "");
