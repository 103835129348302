import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useIntercom from "@reclaim-ai/react-intercom-hook";
import { useAuthContext } from "../../auth/AuthProvider";
import { useCompanyContext } from "../companies/useCompanyContext";

export const UpdateIntercom = (): void => {
  const intercom = useIntercom();
  const { currentUser } = useAuthContext();
  const { companyName, companyId } = useCompanyContext();
  const location = useLocation();
  useEffect(() => {
    const email = currentUser?.emails?.[0].email;
    const intercomSettings =
      companyName && email
        ? {
            email,
            name: currentUser ? `${currentUser.firstName} ${currentUser.familyName}` : undefined,
            user_id: currentUser?.username,
            company: {
              company_id: companyId,
              name: companyName,
            },
          }
        : undefined;
    intercom("update", intercomSettings);
  }, [intercom, location, currentUser, companyName, companyId]);
};
