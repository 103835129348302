import { styled } from "@material-ui/styles";

import React from "react";

export const DownloadIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.83331 12.1662C6.66665 12.1662 6.49998 12.0828 6.41665 11.9995L2.16665 7.66618C1.99998 7.49951 1.99998 7.24951 2.16665 7.08285L2.74998 6.49951C2.83331 6.33284 3.16665 6.33285 3.33331 6.58285L6.16665 9.41618V0.916178C6.16665 0.666178 6.33331 0.499512 6.58331 0.499512H7.41665C7.66665 0.499512 7.83331 0.666178 7.83331 0.916178V9.41618L10.6666 6.58285C10.8333 6.41618 11.0833 6.41618 11.25 6.58285L11.8333 7.16618C12 7.33284 12 7.58284 11.8333 7.74951L7.58331 11.9995C7.49998 12.0828 7.33331 12.1662 7.16665 12.1662H6.83331ZM13.5388 13.8333H0.463944C0.298259 13.8333 0.166687 13.9676 0.166687 14.1333V15.2C0.166687 15.3657 0.301002 15.5 0.466687 15.5H13.5334C13.699 15.5 13.8334 15.3657 13.8334 15.2V14.1333C13.8334 13.9676 13.7045 13.8333 13.5388 13.8333Z"
      fill="currentColor"
    />
  </svg>
))({});
