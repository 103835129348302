import { add } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import Moment from "moment";

export const formatMonthsToYearOrMonth = (value: number): string =>
  value % 12 === 0 ? `${value / 12} years` : `${value} months`;

export const formatCentsAsCurrency = (integerAmount: number, maxDecimalPlaces = 2): string =>
  (integerAmount / 100).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDecimalPlaces,
  });

export const formatNumberToTwoDecimal = (integerAmount: number, maxDecimalPlaces = 2): string =>
  (integerAmount / 100).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDecimalPlaces,
  });

export const convertCentsToDollars = (integerAmount: number): string => (integerAmount / 100).toString();

export const formatDollarsAsCurrency = (amount: number, maxDecimalPlaces = 2): string =>
  amount.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDecimalPlaces,
  });

export const convertToCentsToTwoDecimalPlaces = (value: number): number => Math.round(value * 10000) / 100;

export const convertToCentsToFourDecimalPlaces = (value: number): number => Math.round(value * 1000000) / 10000;

export const formatNumberAsString = (integerNumber: number): string => integerNumber.toLocaleString("en");

// Shares must account for a default value of null
export const formatSharesAsString = (shares: number | null): string | null =>
  shares !== null ? shares.toLocaleString("en") : null;

export const convertTextToTitleCase = (text: string | undefined): string =>
  !text ? "" : text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

export const personNameToString = (
  firstName?: string,
  secondName?: string,
  thirdName?: string,
  familyName?: string,
): string => {
  let personName = "";
  personName += !firstName ? "" : firstName;
  personName += !secondName ? "" : ` ${secondName}`;
  personName += !thirdName ? "" : ` ${thirdName}`;
  personName += !familyName ? "" : ` ${familyName}`;
  return convertTextToTitleCase(personName);
};

export const formatDate = (date: Date): string => (date ? Moment(date).format("DD MMM yyyy") : "");

/*
  Takes a date as a string or Date object, checks for a bug, then returns a Date object in Sydney timezone
  
  This ensures that no matter which timezone in the world the user is located, they always view dates in the correct timezone. Default timezone is Sydney because ASIC records all dates according to the Sydney timezone.
*/
export const formatUTCDateToTz = (
  date: string | Date,
  dateFormat = "d MMM yyyy",
  timeZone = "Australia/Sydney",
): string => {
  try {
    /* 
    Converts the date value (string or date object) to a date object in Sydney timezone with same date/time
    e.g. string of "2022-08-03T14:00:00.000Z" (UTC time) becomes...
    Wed Aug 03 2022 14:00:00 GMT+1000 (Australian Eastern Standard Time)
    This allows us to check the date to see if it will cause the bug mentioned below
  */
    const dateInUTC = utcToZonedTime(date, "UTC");
    /*
      Checks and overcomes a specific legacy bug, described on "Dates & Timezones" page of the BOULEVARD Engineering documentation in Confluence, where strings are passed from the frontend to the backend and then a date object is created. The bug occurs when dates are created and saved in Sydney daylight savings time (EDT), but then viewed in non daylight savings time (EST)
    */
    if (dateInUTC.getHours() === 13 && dateInUTC.getMinutes() === 0 && dateInUTC.getSeconds() === 0) {
      // If the date triggers the bug, then add 14 hours to the date passed in, which fixes the bug
      const amendedDate = add(new Date(date), {
        hours: 14,
      });
      return formatInTimeZone(amendedDate, timeZone, dateFormat);
    }

    return formatInTimeZone(date, timeZone, dateFormat);
  } catch (error) {
    return "";
  }
};

export const formatACN = (acn: string): string => {
  if (acn && acn.length === 9) {
    // if the acn does not have spaces, add them
    return `${acn.substring(0, 3)} ${acn.substring(3, 6)} ${acn.substring(6)}`;
  }

  if (acn && acn.length === 11) {
    // if the acn already has spaces, return it as is
    return acn;
  }

  return "";
};

export const formatABN = (abn: string): string =>
  abn && abn.length === 11
    ? `${abn.substring(0, 2)} ${abn.substring(2, 5)} ${abn.substring(5, 8)} ${abn.substring(8)}`
    : "";

export const convertBsb = (bsb: string): string => `${bsb.substring(0, 3)} ${bsb.substring(3, 7)}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addLeadingZeros = (number: number, places = 6) => String(number).padStart(places, "0");

export const toSentenceCase = (camelCase: string): string => {
  const result = camelCase.replace(/([A-Z])/g, " $1");
  return result[0].toUpperCase() + result.substring(1).toLowerCase();
};

export const formatDirectorId = (directorId: string): string => {
  const id = directorId.replace(/\s/g, "").replace(/[^0-9]/g, "");

  const string1 = id.substring(0, 3);
  const string2 = id.length > 3 ? ` ${id.substring(3, 8)}` : "";
  const string3 = id.length > 8 ? ` ${id.substring(8, 13)}` : "";
  const string4 = id.length > 13 ? ` ${id.substring(13, 15)}` : "";

  return `${string1}${string2}${string3}${string4}`;
};

export const formatBeneficiaryABN = (beneficiaryABN: string): string => {
  const abn = beneficiaryABN ? beneficiaryABN.replace(/\s/g, "").replace(/[^0-9]/g, "") : "";

  const string1 = abn.substring(0, 2);
  const string2 = abn.length > 2 ? ` ${abn.substring(2, 5)}` : "";
  const string3 = abn.length > 5 ? ` ${abn.substring(5, 8)}` : "";
  const string4 = abn.length > 8 ? ` ${abn.substring(8, 11)}` : "";

  return `${string1}${string2}${string3}${string4}`;
};

export const formatCompanyACN = (companyACN: string): string => {
  const acn = companyACN ? companyACN.replace(/\s/g, "").replace(/[^0-9]/g, "") : "";

  const string1 = acn.substring(0, 3);
  const string2 = acn.length > 3 ? ` ${acn.substring(3, 6)}` : "";
  const string3 = acn.length > 6 ? ` ${acn.substring(6, 9)}` : "";

  return `${string1}${string2}${string3}`;
};

export const formatTFN = formatCompanyACN;

export const truncateStringWithEllipsis = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) return str;
  return `${str.substring(0, maxLength)}...`;
};

export const roundUnits = (units: string): number => Math.round(Number(units) * 100) / 100;

// 1 wei unit in ethereum is 0.000000000000000001 ether (18 decimal places). we use the same decimal places for dollars when interacting with the liquidise API (ex. used for price limits)
export const convertWeiUnitToDollars = (value: string | undefined): number =>
  value ? Number(value) / Math.pow(10, 18) : NaN;

export const convertDollarAsWeiUnit = (value = 0): string => {
  const dollarValue = value.toString();
  // Split the input value into whole and fractional parts
  // eslint-disable-next-line prefer-const
  let [whole, fractional = ""] = dollarValue.split(".");
  // Pad the fractional part to ensure it is 18 characters long (to represent up to 18 decimal places in wei)
  fractional = fractional.padEnd(18, "0").substring(0, 18);

  // Calculate the wei value by converting the whole part to wei and adding the fractional part
  const wei = BigInt(whole) * BigInt("1000000000000000000") + BigInt(fractional);

  return wei.toString();
};
