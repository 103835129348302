import React from "react";
import { Theme } from "@material-ui/core";
import Select, { SelectProps } from "@material-ui/core/Select";
import { withStyles } from "@material-ui/styles";
import { color } from "../theme/Design";

interface MuiSelectProps {
  backgroundColour?: string;
}

export const MuiSelect = withStyles((theme: Theme) => ({
  root: {
    color: color.gray500,
    fontSize: theme.typography.fontSize,
    height: "40px",
    padding: "0px 15px",
    boxSizing: "border-box",
    lineHeight: "40px",
    minWidth: "200px",
    borderRadius: "4px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: color.gray500,
    background: getBackgroundColour || "transparent !important",
    "&:focus": {
      color: theme.palette.primary.main,
    },
    '&[aria-pressed="true"] + input + .MuiSelect-icon': {
      transform: "rotate(180deg)",
    },
    "&.MuiFilledInput-input, &.MuiInputLabel-root": {
      color: color.black,
      background: getBackgroundColour,
    },
    "&:focus .MuiSelect-icon": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    width: "27px",
    height: "28px",
    color: color.black800,
  },
  select: {
    padding: "0px 15px 0px",
  },
}))((props: SelectProps) => (
  <Select
    {...props}
    variant="filled"
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
      ...props.MenuProps,
    }}
  />
));

const getBackgroundColour = (props: MuiSelectProps) => props.backgroundColour;
