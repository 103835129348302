import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { styled } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { color } from "../theme/Design";

export const Snackbar = withStyles(() => ({
  root: {
    "& > div": {
      background: color.white,
      borderRadius: 4,
      position: "relative",
      color: "#192243",
      fontSize: 12,
      overflow: "hidden",
      padding: 18,
      boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.09)",
      "& .icon": {
        marginRight: 16,
      },
      "& .MuiIconButton-root": {
        color: "inherit !important",
        "& svg": {
          width: 20,
        },
      },
      "&:before": {
        content: '""',
        height: 4,
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        background: "#ccc",
      },
    },
  },
  variantSuccess: {
    "&:before": {
      background: "linear-gradient(122.08deg, #79EEA8 2.19%, #63F3D9 104.79%) !important",
    },
  },
  variantError: {
    "&:before": {
      background: "linear-gradient(158.28deg, #FD8D8D 15.71%, #F14F4F 102.92%) !important",
    },
  },
  variantWarning: {
    "&:before": {
      background: "linear-gradient(158.28deg, #F8CB1C 15.71%, #F69E1E 102.92%) !important",
    },
  },
  variantInfo: {},
}))(({ ...props }: SnackbarProviderProps) => {
  // add action to all snackbars
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = React.useRef<any>();

  return (
    <SnackbarProvider
      {...props}
      ref={notistackRef}
      preventDuplicate
      iconVariant={{
        error: <ErrorIcon className="icon" />,
        success: <SuccessIcon className="icon" />,
        warning: <WarningIcon className="icon" />,
      }}
      action={(key) => (
        <IconButton size="small" onClick={() => notistackRef!.current.closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    />
  );
});

export const SuccessIcon = styled(({ height = 16, width = 16, ...HTMLProps }) => (
  <svg {...HTMLProps} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="url(#paint0_linear)" />
    <path d="M11.4095 4.59016L6.51755 11.4092L4.59042 9.48211" stroke="#192243" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0.633095"
        y1="-1.80001"
        x2="18.8927"
        y2="0.60095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66F6A0" />
        <stop offset="1" stopColor="#90F4E2" />
      </linearGradient>
    </defs>
  </svg>
))({});

export const ErrorIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34375 13.6563C3.85603 15.1687 5.86272 16 8 16C10.1373 16 12.1473 15.1687 13.6563 13.6563C15.1685 12.1438 16 10.1375 16 8C16 5.86251 15.1685 3.8531 13.6563 2.34375C12.1473 0.831264 10.1373 0 8 0C5.86272 0 3.85268 0.831264 2.34375 2.34375C0.831473 3.8531 0 5.86251 0 8C0 10.1375 0.831473 12.1469 2.34375 13.6563ZM7 4C7 3.44685 7.44643 3 8 3C8.55357 3 9 3.44685 9 4V9C9 9.55315 8.55357 10 8 10C7.44643 10 7 9.55315 7 9V4ZM9 12C9 11.4468 8.55357 11 8 11C7.44643 11 7 11.4468 7 12C7 12.5532 7.44643 13 8 13C8.55357 13 9 12.5532 9 12Z"
      fill="#F14F4F"
    />
  </svg>
))({});
export const WarningIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34375 13.6563C3.85603 15.1687 5.86272 16 8 16C10.1373 16 12.1473 15.1687 13.6563 13.6563C15.1685 12.1438 16 10.1375 16 8C16 5.86251 15.1685 3.8531 13.6563 2.34375C12.1473 0.831264 10.1373 0 8 0C5.86272 0 3.85268 0.831264 2.34375 2.34375C0.831473 3.8531 0 5.86251 0 8C0 10.1375 0.831473 12.1469 2.34375 13.6563ZM7 4C7 3.44685 7.44643 3 8 3C8.55357 3 9 3.44685 9 4V9C9 9.55315 8.55357 10 8 10C7.44643 10 7 9.55315 7 9V4ZM9 12C9 11.4468 8.55357 11 8 11C7.44643 11 7 11.4468 7 12C7 12.5532 7.44643 13 8 13C8.55357 13 9 12.5532 9 12Z"
      fill="#F14F4F"
    />
  </svg>
))({});

export const TriangleWarningIcon = styled(({ width = 20, height = 20, ...HTMLProps }) => (
  <svg {...HTMLProps} width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 8.57134C13.5 9.96701 13.5 10.5196 13.4999 11.4289C13.4999 12.2179 12.8284 12.8571 12 12.8571C11.1716 12.8571 10.5001 12.2179 10.5 11.4289C10.5 10.5196 10.5 9.96701 10.5 8.57134C10.5 7.17567 10.5 7.69451 10.5 6.78518C10.5001 5.99625 11.1716 5.35706 12 5.35706C12.8284 5.35706 13.4999 5.99625 13.4999 6.78518C13.5 7.69451 13.5 7.17567 13.5 8.57134ZM12 17.5001C11.1716 17.5001 10.5 16.8605 10.5 16.0715C10.5 15.2825 11.1716 14.6429 12 14.6429C12.8284 14.6429 13.5 15.2825 13.5 16.0715C13.5 16.8605 12.8284 17.5001 12 17.5001ZM23.7325 16.8433L13.7005 1.05397C13.3438 0.393304 12.7085 0 12 0C11.2914 0 10.6562 0.393304 10.2995 1.05397L0.267539 16.8433C-0.0891798 17.4988 -0.0891798 18.2853 0.267539 18.946C0.624212 19.6067 1.25946 20 1.96803 20H22.032C22.7405 20 23.3758 19.6067 23.7325 18.946C24.0892 18.2906 24.0892 17.504 23.7325 16.8433Z"
      fill="#FF8600"
    />
  </svg>
))({});

export const SuccessIconWrapper = styled("span")(() => ({
  paddingRight: 5,
  position: "relative",
  bottom: 2,
}));
