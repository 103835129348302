import React from "react";
import { styled } from "@material-ui/core";

interface HTMLProps {}
// eslint-disable-next-line no-shadow
export const FormRow = styled(({ ...HTMLProps }: HTMLProps) => <div {...HTMLProps} />)({
  marginBottom: 24,
  "& h5": {
    marginBottom: 40,
  },
});

export const NameSection = styled("div")({
  display: "grid",
  gridTemplateColumns: "100px 1fr 1fr 1fr",
  alignItems: "flex-start",
  gridGap: "0 17px",
  "& .MuiFormControl-fullWidth": {
    marginBottom: 18,
  },
});

export const FourNameSection = styled("div")({
  display: "grid",
  gridTemplateColumns: "100px 1fr 1fr 1fr 1fr",
  alignItems: "flex-start",
  gridGap: "0 17px",
  "& .MuiFormControl-fullWidth": {
    marginBottom: 18,
  },
});

export const BirthLocationSection = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  alignItems: "flex-start",
  gridGap: "0 17px",
});

export const FormGroupGrid = styled(NameSection)({
  gridTemplateColumns: "1fr 1fr",
  gridGap: "0 20px",
});

export const FormDateGroupGrid = styled(NameSection)({
  gridTemplateColumns: "202px 1fr",
  gridGap: "0 20px",
});

interface MobileFieldProps {
  children: React.ReactNode;
  className?: string;
  isMandatory?: boolean;
  label?: string;
}

export const MobileField = styled(({ label, className, isMandatory = true, ...props }: MobileFieldProps) => (
  <div {...props} className={`mobile-field ${className}`}>
    {label && (
      <label>
        {label}
        {isMandatory ? "*" : ""}
      </label>
    )}
    {props.children}
  </div>
))({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  "& > label": {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    marginBottom: "8px",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1,
  },
  "& .MuiFormControl-root": {
    marginBottom: "0 !important",
    "& label": {
      display: "none",
    },
    "& input": {
      height: "36px",
    },
    "& .input-wrap": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: "100%",
    },
  },
});
