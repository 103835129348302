import { IOfficeholder } from "@boulevard1/mystake-api-sdk";
import { personNameToString } from "./formatter";
import { IFormSelectValues } from "../uiComponents";

export const mergeOfficeHolders = (officeHolders: { [key: string]: IOfficeholder[] }): IOfficeholder[] =>
  Object.values(officeHolders).reduce((acc, val) => acc.concat(val), []);

export const getAuthorisingOfficerOptions = (officeHolders: {
  [key: string]: IOfficeholder[];
}): IFormSelectValues[] => {
  const merged = mergeOfficeHolders(officeHolders);

  // remove duplicate if the same person is both a director and company secretary
  const uniqueOfficeHolders = merged.filter(
    (officeHolder, index, self) => index === self.findIndex((t) => t._id === officeHolder._id),
  );

  return uniqueOfficeHolders.map((officeHolder) => ({
    label: personNameToString(officeHolder.name.firstName, undefined, undefined, officeHolder.name.familyName),
    value: officeHolder._id,
    description: officeHolder.roles.some((role) => role.role === "DIR") ? "Director" : "Company Secretary",
  }));
};
