import { PropTypes, SvgIcon, Theme, makeStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { styled, withStyles } from "@material-ui/styles";
import React, { ElementType } from "react";
import { Colour, color, dashboardColor } from "../theme/Design";
import { theme } from "../theme/Theme";
import { LoadingCircle } from "./icons/LoadingCircle";

interface MuiButtonProps extends ButtonProps {
  themeType?: "dark" | "light";
  loader?: boolean;
  fill?: boolean;
  style?: React.CSSProperties;
  component?: ElementType;
}

export const MuiButton = styled(({ loader, themeType, fill, style, ...other }: MuiButtonProps) => (
  <Button
    variant={other?.variant || "contained"}
    color={other?.color || "primary"}
    style={
      !fill
        ? {
            width: "fit-content",
            minWidth: 110,
            height: 36,
            paddingRight: 15,
            paddingLeft: 15,
            ...style,
            position: "relative",
          }
        : { ...style }
    }
    {...other}
  >
    {!loader && other.children}
    {loader && <LoadingCircle color="inherit" thickness={5} size={18} />}
  </Button>
))({
  whiteSpace: "nowrap",
  minHeight: 36,
  fontWeight: 600,
  fontSize: 14,
  textTransform: "inherit",
  boxShadow: (props: MuiButtonProps) =>
    theme.palette.background.default === dashboardColor.bg || props.themeType === "light"
      ? "0px 4px 4px rgba(0, 0, 0, 0.25) !important"
      : "none !important",
  letterSpacing: "0.16px",
  "&.Mui-disabled": {
    boxShadow: "none !important",
  },
  "& .MuiCircularProgress-colorPrimary": {
    color: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.slate : color.green),
  },
  "&.MuiButton-containedSecondary": {
    background: theme.palette.grey[800],
    color: color.white,
  },
  "&.MuiButton-containedPrimary": {
    background: theme.color.primary.main,
    "&:hover": {
      background: theme.palette.primary.light,
    },
    "&.Mui-disabled": {
      background: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[300] : theme.palette.primary.light,
      color: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.white : Colour.slate),
      "& .MuiCircularProgress-colorPrimary": {
        color: Colour.slate,
      },
    },
  },
  "&.MuiButton-outlinedPrimary": {
    border: "1px solid",
    borderColor: (props: MuiButtonProps) => (props.themeType === "light" ? color.info : theme.palette.primary.main),
    color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    "&:hover": {
      borderColor: theme.palette.primary.light,
      background: theme.palette.primary.light,
      color: theme.palette.grey[800],
    },
    "&.Mui-disabled": {
      borderColor: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.primary.main,
      color: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.primary.main,
    },
  },
  "&.MuiButton-outlinedSecondary": {
    border: "2px solid",
    borderColor: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.white : Colour.mint),
    color: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.white : Colour.mint),
    "&:hover": {
      borderColor: (props: MuiButtonProps) => Colour.mint,
      background: "transaprent",
      color: Colour.mint,
    },
    "&.Mui-disabled": {
      borderColor: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.grey : Colour.mintlight),
      color: (props: MuiButtonProps) => (props.themeType === "light" ? Colour.grey : Colour.mintlight),
    },
  },
  "& span, &.MuiButton-endIcon.MuiButton-iconSizeMedium": { margin: 0 },
  "&.MuiButton-textPrimary, &.MuiButton-text": {
    color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    border: 0,
    "&:hover": {
      background: theme.palette.primary.light,
      color: (props: MuiButtonProps) => (props.themeType === "light" ? theme.palette.grey[800] : color.white),
    },
    "&.Mui-disabled": {
      color: (props: MuiButtonProps) =>
        props.themeType === "light" ? theme.palette.grey[500] : theme.color.disabledColor,
    },
    "&:not(:hover), &:not(.Mui-disabled)": {
      boxShadow: "none !important",
    },
  },
  "&.MuiButton-textPrimary": {
    color: (props: MuiButtonProps) =>
      props.themeType === "light" ? theme.color.primary.dark : theme.palette.primary.main,
  },
  background: (props: MuiButtonProps) => (props.variant && props.variant !== "text" ? "" : "transparent"),
});

export const BtnGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& button, & .MuiButton-root": {
    marginLeft: "20px",
  },
  "&.align-left": {
    justifyContent: "flex-start",
    "& button, & .MuiButton-root": {
      marginLeft: "0px",
      marginRight: 20,
    },
  },
  "&.align-center": {
    justifyContent: "center",
    "& button + button, & .MuiButton-root + .MuiButton-root": {
      marginLeft: "20px",
    },
  },
});

/**
 * Use for clickable buttons with icons and no text
 */
export const IconButton = withStyles((muiTheme: Theme) => ({
  root: {
    color: color.gray,
    width: 26,
    height: 26,
    padding: 0,
    borderRadius: 4,
    "&:hover": {
      background: muiTheme.palette.primary.light,
      borderColor: muiTheme.palette.primary.light,
      color: color.black,
    },
  },
  colorSecondary: {
    borderColor: "transparent",
    background: muiTheme.palette.grey[800],
    color: muiTheme.palette.common.white,
  },
  colorPrimary: {
    borderColor: "transparent",
    background: muiTheme.palette.primary.light,
    color: muiTheme.palette.grey[800],
  },
}))((props: IconButtonProps) => <MuiIconButton {...props} />);

interface IconTextProps extends ButtonProps {
  icon: ElementType;
  iconProps?: React.ComponentProps<typeof SvgIcon>;
  iconPosition?: "left" | "right";
  buttonText?: string;
  buttonColour?: PropTypes.Color;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  iconLeft: {
    width: 20,
    height: 20,
    marginRight: 4,
    "&:hover $icon": {
      color: theme.palette.grey[800],
    },
  },
  iconRight: {
    width: 20,
    height: 20,
    marginLeft: 4,
    "&:hover $icon": {
      color: theme.palette.grey[800],
    },
  },
  outlined: {
    borderColor: Colour.white,
    color: Colour.white,
    "&:hover": {
      borderColor: Colour.mintlight,
      background: "transparent",
      color: Colour.mintlight,
    },
  },
});

/**
 * Use for clickable buttons with icons and text
 */
export const IconTextButton: React.FC<IconTextProps> = ({
  icon: Icon,
  iconProps,
  iconPosition = "left",
  buttonText,
  variant = "text",
  buttonColour = "primary",
  onClick,
  style,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant}
      color={buttonColour}
      size="small"
      themeType="light"
      startIcon={iconPosition === "left" ? <Icon className={classes.iconLeft} style={iconProps} /> : null}
      endIcon={iconPosition === "right" ? <Icon className={classes.iconRight} style={iconProps} /> : null}
      onClick={onClick}
      style={{ padding: "5px 8px 5px 8px", marginLeft: -8, minWidth: 0, ...style }}
      className={variant === "outlined" ? classes.outlined : ""}
      {...props}
    >
      {buttonText}
    </MuiButton>
  );
};
