import { styled } from "@material-ui/core";
import React from "react";
import { color } from "../theme/Design";

interface LinearProgressBarProps {
  firstValue: number;
  secondValue?: number;
  totalValue: number;
  barType?: string;
}

export const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
  firstValue,
  secondValue,
  barType = "single",
  totalValue,
}) => (
  <Container>
    <FirstBar barType={barType} style={{ width: `${(firstValue / totalValue) * 100}%` }} />
    {barType !== "single" && (
      <>{secondValue ? <SecondBar barwidth={`${((secondValue - firstValue) / totalValue) * 100}%`} /> : ""}</>
    )}
  </Container>
);

const Container = styled("div")({
  width: "100%",
  height: "25px",
  border: `1px solid ${color.black800}`,
  borderRadius: 4,
  display: "flex",
  "& div": {
    height: 23,
  },
});

const FirstBar = styled("div")({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  backgroundColor: (props: { barType: string }) => (props.barType === "single" ? "#C9DBF1" : "#323B70"),
});

const SecondBar = styled("div")({
  backgroundColor: color.info,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  width: (props: { barwidth: string }) => props.barwidth,
});
