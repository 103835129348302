import React from "react";
import { Timelapse } from "@material-ui/icons";

interface TimelapseIconProps {
  colour?: string;
}

export const TimelapseIcon: React.FC<TimelapseIconProps> = ({ colour }) => (
  <Timelapse style={{ color: colour, fontSize: 16, marginRight: 8, marginBottom: 2 }} />
);
