import { CircularProgress, CircularProgressProps } from "@material-ui/core";

import React from "react";

export interface ILoadingCircleProps extends CircularProgressProps {}

export const LoadingCircle: React.FC<ILoadingCircleProps> = ({ size = 20, thickness = 6, color = "#fff", ...rest }) => (
  <>
    <CircularProgress
      style={{ ...rest.style, position: "absolute", opacity: 0.2 }}
      variant="determinate"
      size={size}
      thickness={thickness}
      value={100}
    />
    <CircularProgress
      style={{ ...rest.style, position: "absolute" }}
      variant="indeterminate"
      size={size}
      disableShrink
      thickness={thickness}
    />
  </>
);
