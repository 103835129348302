import React from "react";
import { styled, CircularProgress } from "@material-ui/core";

interface HTMLProps {}
// eslint-disable-next-line no-shadow
export const Loader = styled(({ ...HTMLProps }: HTMLProps) => (
  <div {...HTMLProps}>
    <CircularProgress color="inherit" size={40} disableShrink />
  </div>
))({
  textAlign: "center",
  padding: 40,
});
