import { useEffect } from "react";
import { sessionService } from "../../../util";

const sessionValidationEnabled = process.env.REACT_APP_SESSION_VALIDATION_ENABLED === "true";
// Custom hook to track user activity
export const useActivityTracker = (): void => {
  useEffect(() => {
    const events: string[] = ["click", "keypress", "scroll"];
    const updateActivity = (): void => {
      const now = new Date().toISOString();
      localStorage.setItem("lastActivity", now);
    };
    if (sessionValidationEnabled) {
      events.forEach((event) => {
        window.addEventListener(event, updateActivity);
      });
    }

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, updateActivity);
      });
    };
  }, []);
};

// Custom hook to save last activity when the tab or browser is closed
export const useSaveOnClose = (): void => {
  useEffect(() => {
    const handleTabClose = async (): Promise<void> => {
      const hasSession = localStorage.getItem("sessionId");
      if (!hasSession) {
        return;
      }
      const now = new Date().toISOString();
      localStorage.setItem("lastActivity", now);
      await sessionService.updateLastAccess(new Date(now));
      localStorage.setItem("lastSynced", now);
    };
    if (sessionValidationEnabled) {
      window.addEventListener("beforeunload", handleTabClose);
      window.addEventListener("visibilitychange", handleTabClose);
    }
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
      window.removeEventListener("visibilitychange", handleTabClose);
    };
  }, []);
};

// Custom hook to check for user inactivity
export const useInactivityTimeout = (): void => {
  useEffect(() => {
    const checkActivity = async (): Promise<void> => {
      const hasSession = localStorage.getItem("sessionId");
      if (!sessionValidationEnabled || !hasSession) {
        return;
      }
      const lastActivity = localStorage.getItem("lastActivity");
      const lastSynced = localStorage.getItem("lastSynced");

      const now = new Date();
      // 15 mins sync gap in ms
      const syncGap = 15 * 60 * 1000;
      // 1hr inactivity threshold in ms
      const inactivityThreshold = 60 * 60 * 1000;

      const lastSyncedDate = lastSynced ? new Date(lastSynced) : new Date();
      const lastActivityDate = lastActivity ? new Date(lastActivity) : new Date();

      if (now.getTime() - lastSyncedDate.getTime() > syncGap) {
        sessionService.updateLastAccess(lastActivityDate);
        localStorage.setItem("lastSynced", now.toString());
      }

      // User has been inactive for more than 1hr.
      if (now.getTime() - lastActivityDate.getTime() > inactivityThreshold) {
        await sessionService.revokeSession();
      }
    };

    const interval = setInterval(checkActivity, 10 * 60 * 1000); // check every 10 minutes
    return () => clearInterval(interval);
  }, []);
};
