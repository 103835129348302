import { styled } from "@material-ui/styles";
import React from "react";
import { Colour } from "../../theme/Design";

/**
 * Black tick with mint background
 */
export const CircleCheckIcon = styled(({ ...HTMLProps }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill={Colour.mint} />
    <circle cx="10" cy="10" r="10" fill="black" fillOpacity="0.05" />
    <path d="M14.2621 5.74219L8.14719 14.266L5.73828 11.8571" stroke={Colour.slate} />
  </svg>
))({});
