import { IUserProfile } from "@boulevard1/mystake-api-sdk";
import { IInvestorProfiles, IInvestorRequest, Roles, ShareholderStatus } from "@boulevard1/mystake-common-sdk";
import { InvestorType } from "@boulevard1/mystake-validator-public";
import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Order } from "../constants/constants";
import { authService, companyService, userService } from "../util";

interface AppContextInterface {
  currentUser: IUserProfile | null;
  updateUser: () => Promise<void>;
  invalidateCurrentUser: () => void;
  loader: boolean;
  setIsInvestor: (isInvestor: boolean) => void;
  /**
   * External investors are external users who have signed in to create an investor profile.
   *
   * The role is assigned in the AuthProvider to account for users bypassing the onboarding flow
   * and to share the state with other providers on page load.
   */
  setExternalInvestor: (acn: string, companyId?: string) => Promise<void>;
  getUpdatedUser: () => Promise<IUserProfile | null>;
  getInvestors: (
    companyId: string,
    page?: number,
    rowsPerPage?: number,
    sortBy?: string,
    sortOrder?: Order,
    keyword?: string,
    filterByCompany?: boolean,
    status?: ShareholderStatus[],
  ) => Promise<IInvestorProfiles | null>;
}

export const AuthContext = React.createContext<AppContextInterface>({
  currentUser: null,
  updateUser: async (): Promise<void> => {},
  invalidateCurrentUser: () => {},
  loader: true,
  setIsInvestor: () => {},
  setExternalInvestor: async (): Promise<void> => {},
  getUpdatedUser: async (): Promise<IUserProfile | null> => null,
  getInvestors: async (): Promise<IInvestorProfiles | null> => null,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUserProfile | null>(null);
  const [loader, showLoader] = useState(true);
  const [isInvestor, setIsInvestor] = useState(false);
  const [isExternalInvestor, setIsExternalInvestor] = useState<boolean>(false);

  const location = useLocation();
  const matchSignin = useRouteMatch("/signin");
  let acnParam: string | null = null;

  if (matchSignin && location.search) {
    const queryParams = new URLSearchParams(location.search.toLowerCase());
    // Emails for external users are verified during sign in
    acnParam = queryParams.get("acn");
  }

  useEffect(() => {
    if (currentUser) {
      setCurrentUser({
        ...currentUser,
        isInvestor,
        isExternalInvestor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvestor, isExternalInvestor, setCurrentUser]);

  useEffect(() => {
    if (acnParam) {
      setExternalInvestor(acnParam);
    }
  }, [acnParam]);

  const updateUser = async (): Promise<void> => {
    try {
      if (!currentUser) {
        await setUser();
      } else {
        const userProfile = await userService.getUser();
        userProfile.isInvestor = isInvestor;
        userProfile.isExternalInvestor = isExternalInvestor;
        setCurrentUser(userProfile);
      }
    } catch (error) {
      setCurrentUser(null);
    }
    showLoader(false);
  };

  const getUpdatedUser = async (): Promise<IUserProfile | null> => {
    try {
      const userProfile = await userService.getUser();
      userProfile.isInvestor = isInvestor;
      userProfile.isExternalInvestor = isExternalInvestor;
      return userProfile;
    } catch (error) {
      setCurrentUser(null);
      return null;
    }
  };

  const setUser = async (): Promise<void> => {
    try {
      const userProfile = await userService.getUser();
      const isAdmin = !!userProfile.roles.includes(Roles.ADMIN);
      const isExternal = !!userProfile.roles.includes(Roles.EXTERNAL_INVESTOR);
      setIsInvestor(!isAdmin);
      userProfile.isInvestor = !isAdmin;
      setIsExternalInvestor(isExternal);
      userProfile.isExternalInvestor = isExternal;
      setCurrentUser(userProfile);
    } catch (error) {
      setCurrentUser(null);
    }
    showLoader(false);
  };

  const invalidateCurrentUser = async () => {
    setCurrentUser(null);
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await authService.currentUser();
      if (user) {
        setUser();
      } else {
        setCurrentUser(null);
        showLoader(false);
      }
    };
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  const setExternalInvestor = async (acn: string, companyId?: string): Promise<void> => {
    try {
      const userProfile = await userService.getUser();
      const shareholdings = userProfile?.shareholdings || [];
      const onboardingCompanyId = companyId || (await companyService.getCompanyByAcn(acn)).payload;
      const hasHoldingsInCompany = shareholdings.length > 0 && shareholdings[0].companyId === onboardingCompanyId;

      if (!hasHoldingsInCompany) {
        const grantedRole = await userService.grantShareholderRole(onboardingCompanyId, Roles.EXTERNAL_INVESTOR);
        grantedRole && setIsExternalInvestor(true);
      }
    } catch (error) {
      setIsExternalInvestor(false);
    }
    showLoader(false);
  };

  const getInvestors = async (
    companyId: string,
    page = 1,
    rowsPerPage = 10,
    sortBy = "legalEntityName",
    sortOrder: Order = "asc",
    keyword = "",
    filterByCompany = false,
    status: ShareholderStatus[] = [ShareholderStatus.ACCEPTED],
  ): Promise<IInvestorProfiles | null> => {
    try {
      const response = await userService.getInvestorProfiles(
        companyId,
        filterByCompany,
        status,
        keyword,
        page,
        rowsPerPage,
        sortBy,
        sortOrder,
      );

      const sophisticatedInvestors = response.payload.investors.filter(
        (investor: IInvestorRequest) => investor.investorType === InvestorType.SOPHISTICATED,
      );
      const existingInvestors: IInvestorProfiles = {
        count: sophisticatedInvestors.length,
        investors: sophisticatedInvestors,
      };
      return existingInvestors;
    } catch (error) {
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        updateUser,
        invalidateCurrentUser,
        loader,
        setIsInvestor,
        getUpdatedUser,
        setExternalInvestor,
        getInvestors,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthContext = () => React.useContext(AuthContext);
