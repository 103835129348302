import { io, Socket } from "socket.io-client";

export const getBaseServerUrl = (): string | undefined => {
  // "production" indicates the app is running on a build server such as QA or Production via the "build" web script
  if (process.env.NODE_ENV === "production") {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  }
  // The API base url is declared in the "start" web script in local development
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_BASE_URL;
  }
  return undefined;
};

/**
 * The API path is specified separately as the `io` function only accepts a base URL
 */
export const getSocketPath = (): string => (process.env.NODE_ENV === "development" ? "/socket.io" : "/api/socket.io");

/**
 * The socket client listens for server events and updates the user's page state in real time.
 * @param companyId - To join a room and receive company specific events
 */
export const initialiseSocket = (isAdmin?: boolean, companyId?: string): Socket => {
  const baseUrl = getBaseServerUrl();
  const path = getSocketPath();

  if (!baseUrl) {
    throw new Error("Base URL is not defined");
  }

  const socket = io(baseUrl, {
    path,
    transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
  });

  isAdmin && companyId && socket.emit("joinRoom", companyId);

  return socket;
};
