import { styled } from "@material-ui/core/styles";
import React from "react";
import { Colour } from "../theme/Design";

interface InfoPanelProps {
  children: React.ReactNode;
  background?: string;
  colour?: string;
  padding?: number;
  fontWeight?: number;
  noMargin?: boolean;
  margin?: number | string;
}

export const InfoPanel = styled(({ children, ...HTMLProps }: InfoPanelProps) => <div {...HTMLProps}>{children}</div>)(
  ({ background, colour, padding, fontWeight, noMargin, margin }: InfoPanelProps) => ({
    background: background || Colour.fog,
    color: colour || Colour.slate,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    padding: padding || 15,
    gap: 10,
    borderRadius: 4,
    width: "100%",
    fontWeight: fontWeight || 600,
    margin: noMargin ? 0 : margin || "0 0 16px",
    "&  p:last-child": {
      marginBottom: 0,
    },
  }),
);
