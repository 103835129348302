import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Colour } from "../../theme/Design";

const useStyles = makeStyles({
  dropdown: {
    /* Icons BLVD */
    boxSizing: "border-box",

    /* Auto layout */
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    gap: 4,

    width: 20,
    height: 20,

    background: Colour.white,
    border: `1px solid ${Colour.greyLightInfo}`,
    boxShadow: "0px 2px 2px #00000040",
    borderRadius: 4,

    /* Inside auto layout */
    flex: "none",
    order: 1,
    flexGrow: 0,
    zIndex: 0,
  },
  arrow: {
    color: Colour.grey,
  },
});

export const DropdownSymbol: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.dropdown}>
      <ArrowDropDownIcon className={classes.arrow} />
    </div>
  );
};

export const DropupSymbol: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.dropdown}>
      <ArrowDropUpIcon className={classes.arrow} />
    </div>
  );
};
