import { styled } from "@material-ui/styles";
import React from "react";

export const InvestorsIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 14" fill="none">
    <path
      d="M14.0909 7.40225C13 7.40225 11.3 7.70902 10 8.30451C8.7 7.69999 7 7.40225 5.90909 7.40225C3.93636 7.40225 0 8.37669 0 10.3346V13.8158H20V10.3346C20 8.37669 16.0636 7.40225 14.0909 7.40225ZM10.4545 12.4624H1.36364V10.3346C1.36364 9.84736 3.69091 8.75563 5.90909 8.75563C8.12727 8.75563 10.4545 9.84736 10.4545 10.3346V12.4624ZM18.6364 12.4624H11.8182V10.3346C11.8182 9.91954 11.6364 9.55864 11.3455 9.23383C12.1455 8.96315 13.1273 8.75563 14.0909 8.75563C16.3091 8.75563 18.6364 9.84736 18.6364 10.3346V12.4624ZM5.90909 6.49999C7.66364 6.49999 9.09091 5.08345 9.09091 3.3421C9.09091 1.60075 7.66364 0.184204 5.90909 0.184204C4.15455 0.184204 2.72727 1.60075 2.72727 3.3421C2.72727 5.08345 4.15455 6.49999 5.90909 6.49999ZM5.90909 1.53759C6.90909 1.53759 7.72727 2.34962 7.72727 3.3421C7.72727 4.33458 6.90909 5.14661 5.90909 5.14661C4.90909 5.14661 4.09091 4.33458 4.09091 3.3421C4.09091 2.34962 4.90909 1.53759 5.90909 1.53759ZM14.0909 6.49999C15.8455 6.49999 17.2727 5.08345 17.2727 3.3421C17.2727 1.60075 15.8455 0.184204 14.0909 0.184204C12.3364 0.184204 10.9091 1.60075 10.9091 3.3421C10.9091 5.08345 12.3364 6.49999 14.0909 6.49999ZM14.0909 1.53759C15.0909 1.53759 15.9091 2.34962 15.9091 3.3421C15.9091 4.33458 15.0909 5.14661 14.0909 5.14661C13.0909 5.14661 12.2727 4.33458 12.2727 3.3421C12.2727 2.34962 13.0909 1.53759 14.0909 1.53759Z"
      fill="white"
    />
  </svg>
))({});
