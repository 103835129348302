import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { ToggleButton as MuiToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { Colour } from "../theme/Design";

const StyledToggleButtonGroup = withStyles({
  grouped: {
    "&:first-child": {
      // approve button styling
      minWidth: "auto",
      minHeight: "auto",
      maxWidth: 28,
      maxHeight: 22,
      padding: 3,
      color: Colour.white,
      backgroundColor: Colour.green,
      "&.Mui-selected": {
        color: Colour.white,
        backgroundColor: Colour.green,
      },
      "&:not(.Mui-selected)": {
        color: Colour.grey,
        backgroundColor: Colour.greyLightInfo,
      },
    },
    "&:not(:first-child)": {
      // reject button styling
      minWidth: "auto",
      minHeight: "auto",
      maxWidth: 28,
      maxHeight: 22,
      padding: 3,
      color: Colour.grey,
      backgroundColor: Colour.greyLightInfo,
      "&.Mui-selected": {
        color: Colour.white,
        backgroundColor: Colour.red,
      },
      "&:not(.Mui-selected)": {
        color: Colour.grey,
        backgroundColor: Colour.greyLightInfo,
      },
    },
  },
})(ToggleButtonGroup);

export const ToggleButton: React.FC<{ onChange: (value: boolean) => void; status?: boolean }> = ({
  onChange,
  status = true,
}) => (
  <StyledToggleButtonGroup
    value={status}
    exclusive
    onChange={() => {
      onChange(!status);
    }}
    aria-label="approval status"
  >
    <MuiToggleButton value aria-label="approved">
      <DoneIcon style={{ width: 21, height: 16 }} />
    </MuiToggleButton>
    <MuiToggleButton value={false} aria-label="rejected">
      <CloseIcon style={{ width: 21, height: 16 }} />
    </MuiToggleButton>
  </StyledToggleButtonGroup>
);
