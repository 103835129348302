import React from "react";
import { BrowserRouter } from "react-router-dom";
import { styled } from "@material-ui/core";
import useIntercom from "@reclaim-ai/react-intercom-hook";
import { Snackbar } from "./components/uiComponents";
import { ThemeProviderContainer as Theme } from "./components/theme";
import { CompanyContextProvider } from "./components/companies/useCompanyContext";
import { AuthProvider } from "./auth/AuthProvider";
import { CheckOnboardingFlow } from "./components/CheckOnboardingFlow";
import { Router } from "./routes/Router";
import { routes } from "./routes/RoutesConfig";
import { useActivityTracker, useInactivityTimeout, useSaveOnClose } from "./components/utility/hooks/activitytracker";

export const App: React.FC = () => {
  useIntercom({ app_id: window.config.INTERCOM_APP_ID });
  useInactivityTimeout();
  useActivityTracker();
  useSaveOnClose();

  // BrowserRouter must wrap AuthProvider to give access to react-router-dom
  return (
    <BrowserRouter>
      <AuthProvider>
        <Theme>
          <Snackbar maxSnack={6}>
            <div className="App">
              <CompanyContextProvider>
                <Main>
                  <CheckOnboardingFlow />
                  <Router routes={routes} />
                </Main>
              </CompanyContextProvider>
            </div>
          </Snackbar>
        </Theme>
      </AuthProvider>
    </BrowserRouter>
  );
};

const Main = styled("main")({
  minHeight: "100vh",
});
