import {
  ICompanyShareholder,
  IExistingShareholder,
  IExternalInvestor,
  IExternalInvestorForm,
  IIndividualShareholder,
  IShareholderFormV2,
  IShareholderWithHoldings,
  ITrustOrSmsfCompanyTrustee,
  ITrustOrSmsfIndividualTrustee,
} from "@boulevard1/mystake-validator-public";
import { IShareholderV2 } from "@boulevard1/mystake-validator-public/lib/contracts/IShareholderV2";
import { InvestorsTableColumns } from "../components/companies/investors/InvestorsTable";

export enum PageRoutes {
  COMPANIES_LIST = "/companies-list",
  SWITCH_COMPANY = "/switch-company",
}

export type Tier = "tier1" | "tier2" | "tier3" | "tier4";

export enum ActiveTier {
  TIER1 = "tier1",
  TIER2 = "tier2",
  TIER3 = "tier3",
  TIER4 = "tier4",
}

export enum ActivePlan {
  PLAN1 = "plan1",
  PLAN2 = "plan2",
  PLAN3 = "plan3",
}

export const TIER_LIMITS: Record<Tier, number> = {
  tier1: 20,
  tier2: 50,
  tier3: 250,
  tier4: Infinity,
};

export enum OptionGrantStatus {
  EXERCISED = "Exercised",
  VESTED = "Vested",
  PARTIAL = "Part exercised",
  EXPIRED = "Expired",
  SCHEDULED = "Scheduled",
}

export enum TransactionType {
  ISSUE = "issue",
  CONVERTED = "converted",
  PAID = "paid",
}

export enum FundraiseRoundStatus {
  CLOSED = "Closed",
  OPEN = "Open",
}

export const COMPANYLOGOMAXSIZE = 2000000; // 2MB

export const COMPANYLOGOACCEPTEDFORMATS = ["image/png", "image/jpeg"];

export const MEMBERS_REGISTER_DOWNLOAD_TEMPLATE = "MembersRegisterImportTemplate.xlsx";

export const MINUTES_PER_WEEK = 60 * 24 * 7; // 10080
export const MINUTES_PER_DAY = 60 * 24; // 1440
export const SECONDS_PER_DAY = 60 * 60 * 24; // 86400;
export const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24; // 86400000;
export const MILLISECONDS_PER_HOUR = 1000 * 60 * 60; // 3600000;
export const TOTAL_PAID_AMOUNT = "Total paid amount";

export const PRICE_PER_SHARE = "Price per share";

export const CALCULATION_OPTIONS = [
  {
    label: TOTAL_PAID_AMOUNT,
    value: TOTAL_PAID_AMOUNT,
  },
  {
    label: PRICE_PER_SHARE,
    value: PRICE_PER_SHARE,
  },
];

export const RA72_ERROR_V456 = "V456";

export const noteStatus: Record<string, string> = {
  matured: "Matured",
  issue: "Issued",
  converted: "Converted",
  paid: "Paid out",
};

export enum NoteStatus {
  ISSUE = "issue",
  CONVERTED = "converted",
  PAID = "paid",
}

export const WARNING_28_DAYS_PAST = "Choosing a date 28 days or more in the past will result in an ASIC late fee.";

export const WARNING_OPTIONS_EXPIRED = "The exercise expiry date has passed. To exercise options, update the grant.";

export enum OptionGrantVestingType {
  TIME = "time",
  MANUAL = "manual",
}

export enum UserStatus {
  EMAIL_NOT_VERIFIED = "emailNotVerified",
  PHONE_NOT_VERIFIED = "phoneNotVerified",
  VERIFY_ID_PENDING = "verifyIDPending",
  GREEN_ID_PENDING = "greenIDPending",
  DONE = "done",
}

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SORT_ORDER = "asc";
export const REVERSE_SORT_ORDER = "desc";

export const INVESTORS_LIST_DEFAULT_SORT_BY = InvestorsTableColumns.NAME;

export enum CreateInvestorAccount {
  TYPE = "1. Enter investor type",
  DETAILS = "2. Enter investor details",
  CONTACTS = "3. Enter contacts",
  CERTIFICATE = "4. Investor certificate",
  REVIEW = "5. Review & finish",
}

export const parentPageTitleMap: { [key: string]: string } = {
  "/company/:companyId/admin-view": "Admin",
  "/company/:companyId/shares-register": "Registers",
  "/company/:companyId/options-register": "Registers",
  "/company/:companyId/notes-register": "Registers",
  "/company/:companyId/register/investor-holdings/:shareholderId": "Investor holdings",
  "/company/:companyId/register/investor-holdings/:shareholderId/edit": "Edit investor",
  "/investor/company/:companyId/register/investor-holdings/:shareholderId/edit": "Edit investor",
  "/investor/company/:companyId/register/investor-holdings/:shareholderId/edit/trading": "Edit investor",
  "/investor/company/:companyId/investor-view/:shareholderId": "Investments",
  "/investor/company/:companyId/investor-view/:shareholderId/accept-and-pay/:fundraiseOfferId": "Accept and pay",
  "/investor/company/:companyId/switch-holding": "Investor profiles",
  "/investor/company/:companyId/switch-holding/trading": "Investor profiles",
  "/investor/company/:companyId/investor-profiles/active": "Investor profiles",
  "/investor/company/:companyId/investor-profiles/requests": "Investor profiles",
  "/investor/company/:companyId/investor-profiles/existing": "Add investor",
  "/investor/company/:companyId/investor-profiles/add": "Add investor",
  "/investor/company/:companyId/investor-view/:shareholderId/switch-holding": "Investments",
  "/investor/company/:companyId/investor-view/:shareholderId/payment-details/:fundraiseOfferId": "Investments",
  "/company/:companyId/shares-register/issue-share": "Issue Shares",
  "/company/:companyId/shares-register/transfer-shares": "Transfer shares",
  "/company/:companyId/company-information": "Company Information",
  "/company/:companyId/company-information/notes-summary": "Notes",
  "/company/:companyId/company-information/create-notes": "Notes",
  "/company/:companyId/company-information/issue-notes": "Notes",
  "/company/:companyId/company-information/notes-certificate": "Notes",
  "/company/:companyId/register/investor-holdings/:shareholderId/notes/:notetransactionId": "Note details",
  "/investor/company/:companyId/investor-view/:shareholderId/notes/:notetransactionId": "Note details",
  "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/notes/:notetransactionId": "Note details",
  "/company/:companyId/company-information/options/pool-list": "Options",
  "/company/:companyId/company-information/options/create-pools": "Options",
  "/company/:companyId/company-information/options/grant": "Options",
  "/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/details": "Options grant",
  "/investor/company/:companyId/investor-view/:shareholderId/options/:optionGrantId/details": "Options grant",
  "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/options/:optionGrantId/details":
    "Options grant",
  "/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/vest": "Options grant",
  "/investor/company/:companyId/investor-view/:shareholderId/options/:optionGrantId": "Options grant",
  "/investor/company/:companyId/investor-view/:shareholderId/switch-holding/options/:optionGrantId": "Options grant",
  "/company/:companyId/register/investor-holdings/:shareholderId/options/:optionGrantId/exercise": "Options grant",
  "/company/:companyId/company-information/officeholders/list": "Officeholders",
  "/company/:companyId/company-information/officeholders/appoint": "Appoint officeholder",
  "/company/:companyId/company-information/officeholders/:officeholderId/edit": "Edit officeholder",
  "/company/:companyId/company-information/officeholders/remove": "Remove officeholder",
  "/company/:companyId/company-information/fundraise": "Company Information",
  "/company/:companyId/company-information/share-price": "Shares",
  "/company/:companyId/company-information/share-certificate": "Shares",
  "/company/:companyId/company-information/share-price/history": "Share price history",
  "/company/:companyId/company-information/registered-address": "Edit Registered Office",
  "/company/:companyId/company-information/principal-place-of-business": "Edit Principal Place of Business",
  "/company/:companyId/investor-relations/announcements": "Investor Relations",
  "/company/:companyId/investor-relations/contacts": "Investor Relations",
  "/company/:companyId/investor-relations/invitations": "Investor Relations",
  "/company/:companyId/investors/all": "Investors",
  "/company/:companyId/investors/requests": "Investors",
  "/company/:companyId/investors/holdings/:shareholderId": "Investor holdings",
  "/company/:companyId/investors/add": "Add investors",
  "/company/:companyId/investors/view-request": "Requests",
  "/company/:companyId/investors/review-request": "Review request",
  "/company/:companyId/documents": "Documents",
  "/company/:companyId/activity": "Activity",
  "/company/:companyId/settings": "Settings",
  "/company/:companyId/settings/administrators": "Administrators",
  "/company/:companyId/investor-relations/announcement/:announcementId": "Announcement",
  "/investor/company/:companyId/investor-relations/announcement": "Announcements",
  "/investor/company/:companyId/investor-relations/investor/:shareholderId/announcement": "Announcements",
  "/investor/company/:companyId/investor-relations/investor/:shareholderId/announcement/:announcementId":
    "Announcement",
  "/company/:companyId/fundraises": "Fundraising",
  "/company/:companyId/fundraises/:fundraiseId/details": "Fundraising ",
  "/company/:companyId/create-fundraise": "Fundraising",
  "/company/:companyId/fundraise-offer": "Fundraising",
  "/company/:companyId/trading/view": "Trading",
  "/company/:companyId/trading/settings": "Trading",
  "/company/:companyId/trading/settings/select-liquidity-provider": "Select liquidity provider",
  "/company/:companyId/trading/settings/select-liquidity-provider/:shareCompanyId/:shareholderId":
    "Select liquidity provider",
  "/company/:companyId/trading/history/:auctionId": "History",
  "/company/:companyId/trading/history": "Trading",
  "/investor/company/:companyId/trading/:shareholderId": "Trading",
  "/investor/company/:companyId/trading/:shareholderId/switch-holding": "Trading",
  "/investor/company/:companyId/trading/:shareholderId/trading-window/buy-shares": "Buy shares",
  "/investor/company/:companyId/trading/:shareholderId/trading-window/sell-shares": "Sell shares",
};

export const navbarDataMap: Record<string, Array<{ label: string; value: string }>> = {
  Registers: [
    {
      label: "Shares",
      value: "shares-register",
    },
    {
      label: "Options",
      value: "options-register",
    },
    {
      label: "Notes",
      value: "notes-register",
    },
  ],
  Notes: [
    {
      label: "View",
      value: "notes-summary",
    },
    {
      label: "Create",
      value: "create-notes",
    },
    {
      label: "Issue",
      value: "issue-notes",
    },
    {
      label: "Certificates",
      value: "notes-certificate",
    },
  ],
  Options: [
    {
      label: "Pools",
      value: "pool-list",
    },
    {
      label: "Create a pool",
      value: "create-pools",
    },
    {
      label: "Grant options",
      value: "grant",
    },
  ],
  "Options grant": [
    {
      label: "Details",
      value: "details",
    },
    {
      label: "Vest",
      value: "vest",
    },
    {
      label: "Exercise",
      value: "exercise",
    },
  ],
  Officeholders: [
    {
      label: "List",
      value: "list",
    },
    {
      label: "Appoint",
      value: "appoint",
    },
    {
      label: "Remove",
      value: "remove",
    },
  ],
  Shares: [
    {
      label: "Classes",
      value: "share-price",
    },
    {
      label: "Certificates",
      value: "share-certificate",
    },
  ],
  Fundraising: [
    {
      label: "Rounds",
      value: "fundraises",
    },
    {
      label: "Create",
      value: "create-fundraise",
    },
    {
      label: "Offer",
      value: "fundraise-offer",
    },
  ],
  "Investor Relations": [
    {
      label: "Announcements",
      value: "announcements",
    },
    {
      label: "Invitations",
      value: "invitations",
    },
    {
      label: "Contacts",
      value: "contacts",
    },
  ],
  Trading: [
    {
      label: "View",
      value: "view",
    },
    {
      label: "History",
      value: "history",
    },
    {
      label: "Settings",
      value: "settings",
    },
  ],
  Investors: [
    {
      label: "All investors",
      value: "all",
    },
    {
      label: "Requests",
      value: "requests",
    },
  ],
  // Investor view
  "Investor profiles": [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Requests",
      value: "requests",
    },
  ],
};

export const companyTypeOptions: Array<{ label: string; value: string }> = [
  {
    label: "Private (Pty. Ltd.)",
    value: "Pty. Ltd.",
  },
  {
    label: "Public (Ltd.)",
    value: "Ltd.",
  },
];

export const defaultAcnDetails = {
  Abn: "",
  Acn: "",
  entityName: "",
  registeredDate: "",
  entityTypeCode: "",
  companyStatus: "",
  companyClass: "",
};

export enum CertificateStatus {
  ISSUE_CERTIFICATE = "issue-certificate",
  NOT_ISSUE_CERTIFICATE = "no-issue-certificate",
}

export type CertificateRadioOptionsType = CertificateStatus.ISSUE_CERTIFICATE | CertificateStatus.NOT_ISSUE_CERTIFICATE;

export const defaultShareCertificateConfig = {
  enableShareCertificates: false,
  prefix: "",
  startNumber: 0,
  officeholders: [{ _id: "", fontFamily: "" }],
};

export const defaultNotesCertificateConfig = {
  enableNotesCertificates: true,
  officeholders: [{ _id: "", fontFamily: "" }],
};

export const defaultContact = {
  salutation: "",
  familyName: "",
  firstName: "",
  secondName: "",
  thirdName: "",
  email: "",
  iso2Code: "AU",
  dialCode: "+61",
  phoneNumber: "",
};

export type EmptyStringType = "";

export type AllShareholderType =
  | IIndividualShareholder
  | ICompanyShareholder
  | ITrustOrSmsfIndividualTrustee
  | ITrustOrSmsfCompanyTrustee
  | IExistingShareholder
  | IShareholderWithHoldings
  | IShareholderV2
  | IExternalInvestor;

export const defaultAddress = {
  careOf: "",
  line2: "",
  street: "",
  locality: "",
  state: "",
  postcode: "",
  country: "Australia",
};

export const defaultShareholder: AllShareholderType = {
  _id: "",
  legalEntityName: "",
  legalEntityAddresses: [defaultAddress],
  contacts: [defaultContact],
  acn: "",
  companyType: "",
  companyName: "",
  abn: "",
  trustName: "",
};

export const defaultOwner = {
  firstName: "",
  middleName: "",
  lastName: "",
  tfn: "",
};

export const defaultContactForm = {
  firstName: "",
  familyName: "",
  email: "",
  iso2Code: "AU",
  dialCode: "+61",
  phoneNumber: "",
};

export const defaultShareholderFormValues: IShareholderFormV2 = {
  investorType: "Sophisticated",
  ownerType: undefined,
  owners: [defaultOwner],
  contacts: [defaultContactForm],
  ownersAddresses: [defaultAddress],
  isTrustOrSMSF: "false",
  beneficiaryName: undefined,
  beneficiaryABN: undefined,
  acn: undefined,
  companyName: undefined,
  isForeignCompany: false,
  companyTfn: undefined,
};

export const defaultExternalInvestorFormValues: IExternalInvestorForm = {
  ...defaultShareholderFormValues,
};

export interface IStep {
  isActive: boolean;
  isCompleted: boolean;
}

export enum TradingShowOfferDetails {
  SHOW = "show",
  HIDE = "hide",
  ERROR = "error",
}

export enum UploadStatus {
  IDLE = "idle",
  UPLOADING = "uploading",
  COMPLETE = "complete",
}

export type Order = "asc" | "desc";

export enum AddressFor {
  REGISTERED = "registered",
  PRINCIPAL = "principal",
}

export interface ICompanyAddressFormData {
  careOf: string;
  streetName: string;
  unitNumber: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export enum InvestorRequestStatus {
  PENDING = "pending",
  DECLINED = "declined",
}

export interface IBaseModal {
  showModal: boolean;
  toggleModal: () => void;
}

export interface IMatchParams {
  companyId?: string;
}

/* App navigation */

export enum NavigationSources {
  fromSignIn = "fromSignIn",
  fromApp = "fromApp",
  directAccess = "directAccess",
}

export enum PageSources {
  adminReviewRequest = "adminReviewRequest",
}

export interface INavigationState {
  base: NavigationSources;
  page?: PageSources;
}
