import { styled } from "@material-ui/styles";

import React from "react";

export const WithdrawIcon = styled(({ ...HTMLProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11348 0.386719C3.46096 0.386719 0.5 3.34768 0.5 7.0002C0.5 10.6527 3.46096 13.6137 7.11348 13.6137C9.01339 13.6137 10.7263 12.8124 11.9325 11.5296L12.0203 11.4362L11.9036 11.3831C11.5645 11.2289 11.2484 11.033 10.9617 10.8018L10.8982 10.7506L10.8398 10.8077C9.87901 11.7481 8.56403 12.3277 7.11348 12.3277C4.17117 12.3277 1.78596 9.94251 1.78596 7.0002C1.78596 4.05789 4.17117 1.67267 7.11348 1.67267C8.77645 1.67267 10.2614 2.4345 11.2385 3.62853L11.2902 3.69169L11.359 3.6478C11.6685 3.45037 12.0042 3.29053 12.3598 3.17467L12.4819 3.13488L12.4048 3.03218C11.1984 1.42597 9.27729 0.386719 7.11348 0.386719ZM7.92266 2.77492H6.38551V3.76352C5.52126 3.9723 4.80649 4.55461 4.80649 5.45787C4.80649 6.00694 5.03663 6.421 5.42936 6.73326C5.81773 7.04205 6.36328 7.25027 6.9974 7.40184C7.55525 7.53511 7.87539 7.69646 8.05566 7.86513C8.23081 8.02901 8.28033 8.20586 8.28033 8.39475C8.28033 8.52849 8.23278 8.70282 8.07551 8.84464C7.91781 8.98685 7.63824 9.10672 7.15409 9.10672C6.70185 9.10672 6.40107 9.0061 6.20905 8.8538C6.01885 8.70294 5.9241 8.49243 5.90112 8.24697L5.89332 8.16368H4.72009L4.72542 8.26059C4.7811 9.272 5.52589 9.8443 6.38551 10.0489V11.0418H7.92266V10.0597C8.34443 9.96985 8.72812 9.8001 9.0125 9.53777C9.31586 9.25794 9.50169 8.87658 9.50169 8.39026C9.50169 7.72056 9.21139 7.26675 8.78877 6.94993C8.37134 6.63699 7.82612 6.45835 7.31244 6.32545C6.80253 6.19353 6.47861 6.06227 6.28314 5.9183C6.18677 5.84732 6.124 5.77492 6.08487 5.69996C6.0459 5.6253 6.02784 5.54321 6.02784 5.44889C6.02784 5.23637 6.12523 5.05585 6.31181 4.92519C6.50102 4.7927 6.78524 4.70998 7.15409 4.70998C7.54226 4.70998 7.79133 4.80225 7.94649 4.94643C8.10117 5.09017 8.17737 5.29934 8.19021 5.56558L8.19443 5.65301H9.37468L9.37078 5.5574C9.33842 4.76597 8.86128 4.03612 7.92266 3.77909V2.77492ZM15.5 7.27585L12.4605 4.31953L11.5994 5.15706L13.1612 6.6788H10.2365V7.8729H13.1612L11.5994 9.39464L12.4605 10.2322L15.5 7.27585Z"
      fill="#343740"
    />
  </svg>
))({});
