import { Logger } from "@boulevard1/mystake-logger-sdk";

const logger = new Logger("console");

// this logs the actual error to server logs, whilst returning a human readable message to the user
export const errorMessage = (error: unknown, message?: string, addDefaultMessage = true): string => {
  let loggerErrorText = "An error occurred";

  if (error instanceof Error) {
    loggerErrorText = error.message;
  }

  if (message) {
    loggerErrorText = `${message}. This is the error message: ${loggerErrorText}`;
  }

  logger.error(loggerErrorText);

  const userErrorMessage = message || "An error occurred.";

  if (!addDefaultMessage) {
    return userErrorMessage;
  }

  return `${userErrorMessage} Please try again in a few minutes. If the error persists, please contact customer support for assistance.`;
};
