/* eslint-disable no-nested-ternary */
import React, { Suspense, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { IRoute } from "./RouteInterface";
import { AuthContext } from "../auth/AuthProvider";
import { PageLoader } from "../components/uiComponents";

export const RouteWithSubRoutes: React.FC<IRoute> = (route) => {
  const { currentUser, loader } = useContext(AuthContext);
  return (
    <Suspense fallback="">
      <Route
        // eslint-disable-next-line react/destructuring-assignment
        path={route.path}
        render={(props) =>
          route.redirect ? (
            <Redirect to={route.redirect} />
          ) : route.private ? (
            loader ? (
              <PageLoader />
            ) : currentUser && !loader ? (
              route.container ? (
                <route.container {...route.containerProps}>
                  {route.component && <route.component {...props} routes={route.routes} />}
                </route.container>
              ) : (
                route.component && <route.component {...props} routes={route.routes} />
              )
            ) : (
              <Redirect to="/signin" />
            )
          ) : route.container ? (
            <route.container {...route.containerProps}>
              {route.component && <route.component {...props} routes={route.routes} />}
            </route.container>
          ) : (
            route.component && <route.component {...props} routes={route.routes} />
          )
        }
      />
    </Suspense>
  );
};
