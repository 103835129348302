import React from "react";
import { styled } from "@material-ui/styles";

export const UploadFileIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
    <path
      id="Vector"
      d="M7.5 0.5H1.5C0.675 0.5 0.00750017 1.175 0.00750017 2L0 14C0 14.825 0.6675 15.5 1.4925 15.5H10.5C11.325 15.5 12 14.825 12 14V5L7.5 0.5ZM10.5 14H1.5V2H6.75V5.75H10.5V14ZM3 10.2575L4.0575 11.315L5.25 10.13V13.25H6.75V10.13L7.9425 11.3225L9 10.2575L6.0075 7.25L3 10.2575Z"
      fill="currentColor"
    />
  </svg>
))({});
