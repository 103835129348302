/* eslint-disable no-nested-ternary */
import { styled } from "@material-ui/core";
import React, { useEffect } from "react";
import { Colour } from "../theme/Design";
import { theme } from "../theme/Theme";

interface IDynamicStepper {
  labels: string[];
  activeStep: number;
}

export const DynamicStepper: React.FC<IDynamicStepper> = ({ labels, activeStep }) => {
  const [stepsData, setStepsData] = React.useState<{
    activeStep: number;
    steps: { label: string; isCompleted: boolean }[];
  }>();

  useEffect(() => {
    const data = labels.map((label: string, index: number) => ({ label, isCompleted: activeStep > index }));
    setStepsData({ activeStep, steps: data });
  }, [activeStep, labels]);

  const StepperComponent =
    labels.length === 3 ? StepperWithThreeSteps : labels.length === 5 ? StepperWithFiveSteps : StepperWithTwoSteps;

  return stepsData ? (
    <StepperComponent>
      {stepsData.steps.map((step, index) => (
        <Step
          key={step.label}
          isActive={stepsData.activeStep === index}
          isComplete={step.isCompleted}
          label={step.label}
          themeType="light"
        />
      ))}
    </StepperComponent>
  ) : null;
};

const Stepper = {
  display: "grid",
  gridGap: 8,
  fontSize: 14,
};

const StepperWithTwoSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr",
}));

export const StepperWithThreeSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr 1fr",
  marginBottom: "64px",
}));

export const StepperWithFourSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridGap: 10,
  marginBottom: "8px",
}));

export const StepperWithFiveSteps = styled("div")(() => ({
  ...Stepper,
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  gridGap: 10,
  marginBottom: "8px",
}));

interface IStep {
  isActive: boolean;
  isComplete: boolean;
  label: string;
  themeType?: "light" | "dark";
}

export const Step = styled(({ isComplete, label, isActive, themeType = "dark", ...HTMLProps }: IStep) => (
  <div {...HTMLProps}>
    <div style={{ display: "inline-block", paddingRight: 8 }}>
      {isComplete && <TickIcon className={themeType === "light" ? "icon-dark" : "icon-green"} />}
    </div>
    <div style={{ display: "inline-block", paddingBottom: 4 }}>{label}</div>
    <hr />
  </div>
))({
  color: (props: IStep) =>
    props.isActive ? (props.themeType === "light" ? theme.palette.grey[800] : Colour.mint20) : Colour.greymid,
  textAlign: "center",
  fontWeight: (props: IStep) => (props.isActive || props.isComplete ? 600 : 400),
  "& hr": {
    border: 0,
    height: 3,
    marginTop: 6,
    marginBottom: 16,
    background: (props: IStep) =>
      props.isActive
        ? props.themeType === "light"
          ? theme.palette.grey[800]
          : theme.color.primary.main
        : Colour.greymid,
    display: "block",
  },
});

const TickIcon = styled(({ ...other }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4215 4.32812L9.41465 19.671L5.07861 15.335" stroke="#C4C4C4" strokeWidth="2" />
  </svg>
))({
  "&.icon-dark path": {
    stroke: Colour.greymid,
  },
  "&.icon-green path": {
    stroke: Colour.mint20,
  },
});
