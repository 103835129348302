import { styled } from "@material-ui/styles";
import React from "react";
import { Colour } from "../../theme/Design";

/**
 * White tick with mint background
 */
export const CircleDoneIcon = styled(({ ...HTMLProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" rx="10" fill={Colour.mint20} />
    <path
      d="M7.81818 12.5766L5.0632 9.68186L4.95455 9.56769L4.84589 9.68186L3.89134 10.6848L3.79293 10.7883L3.89134 10.8917L7.70952 14.9036L7.81818 15.0178L7.92684 14.9036L16.1087 6.30659L16.2071 6.20318L16.1087 6.09977L15.1541 5.09679L15.0455 4.98261L14.9368 5.09679L7.81818 12.5766Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
))({});
