/* eslint-disable import/no-unresolved */
import { ClickAwayListener, FormHelperText, Grow, InputLabel, Paper, Popover, Popper } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import React, { useEffect } from "react";
import { FreeFocusInside } from "react-focus-lock";
import { Control, Controller, FieldError, DeepMap } from "react-hook-form";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List, ListChildComponentProps } from "react-window";
import { Colour, color } from "../theme/Design";
import { theme as muiTheme } from "../theme/Theme";
import { StyledOwnersOption, MuiTextField as TextField } from "./TextField";

export interface IFormSelectValues {
  label: string;
  value: string;
  disabled?: boolean;
  description?: string;
}
interface ReactSelectProps {
  theme?: "dark" | "light";
  value: string;
  setValue: (label: string, value: string, description?: string) => void;
  options: Array<IFormSelectValues>;
  control?: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: DeepMap<any, FieldError>;
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  hideErrorMessage?: boolean;
  errorMessage?: string;
  trigger?: () => void;
  hideSearch?: boolean;
  tabIndex?: number;
  arrName?: string;
  arrIndex?: number;
  arrField?: string;
  large?: boolean;
  id?: string;
  testid?: string;
  disableScroll?: boolean;
  isInTable?: boolean;
  readOnly?: boolean;
  noMarginBottom?: boolean;
}

export const ReactSelect: React.FC<ReactSelectProps> = ({
  theme,
  value,
  setValue,
  options,
  errors,
  name,
  control,
  required,
  label,
  placeholder,
  hideErrorMessage,
  errorMessage,
  trigger,
  hideSearch,
  tabIndex,
  arrName,
  arrIndex,
  arrField,
  large,
  id,
  testid,
  disableScroll,
  isInTable,
  readOnly,
  noMarginBottom,
}) => {
  let helperText = "";
  let hasError = false;
  if (!hideErrorMessage && errors) {
    if (errorMessage) {
      hasError = true;
      helperText = errorMessage;
    } else if (arrName && errors[arrName]) {
      if (errors[arrName][arrIndex as number] && errors[arrName][arrIndex as number][arrField as string]) {
        hasError = true;
        helperText = errors[arrName][arrIndex as number][arrField as string].message;
      } else {
        hasError = false;
        helperText = "";
      }
    } else if (errors[name]) {
      hasError = true;
      helperText = errors[name].message;
    } else {
      hasError = false;
      helperText = "";
    }
  }

  const [popperOpen, setPopperOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [displayedItems, setDisplayedItems] = React.useState<IFormSelectValues[]>(options);
  const [queryText, setQueryText] = React.useState<string>("");

  useEffect(() => {
    if (readOnly && control) {
      control.register(name, { required });
    }
  }, [control, required, readOnly, name]);

  useEffect(() => {
    setDisplayedItems(options);
  }, [options]);

  const handleOnChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setQueryText(searchText);
    if (searchText.trim() === "") {
      setDisplayedItems(options);
    } else {
      const searchResult = options.filter((i) => i.label.toLowerCase().includes(searchText.toLowerCase().trim()));
      setDisplayedItems(searchResult);
    }
  };

  /*
   * @Row: Child select options
   */

  const Row = ({ data, index, style }: ListChildComponentProps) => {
    const { items, selectItemHandler } = data;
    const listItemLabel = items[index]?.label;
    const listItemIcon = items[index]?.icon;
    const listItemDescription = items[index]?.description;
    return (
      <StyledOwnersOption
        onClick={() => selectItemHandler(items[index])}
        style={style}
        className={theme === "light" ? "options-light" : ""}
        selected={value === items[index].value}
        value={items[index].value}
        disabled={items[index].disabled}
      >
        <div>
          {listItemIcon && <IconContainer>{listItemIcon}</IconContainer>}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {listItemLabel}
            {listItemDescription && (
              <span
                style={{
                  fontSize: 12,
                  color: Colour.grey,
                }}
              >
                {listItemDescription}
              </span>
            )}
          </div>
        </div>
      </StyledOwnersOption>
    );
  };

  /*
   * Menu open and close handler
   */
  const handleTogglePopperOpen = () => {
    setPopperOpen(!popperOpen);
  };

  /*
   * Menu close handler
   */
  const handlePopperClose = () => {
    setPopperOpen(false);
    if (!value) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      trigger && trigger();
    }
    if (anchorRef.current) {
      (anchorRef.current as HTMLDivElement).focus();
    }
    setDisplayedItems(options);
    setQueryText("");
  };
  /*
   * Active Menu handler
   */
  const setSelectedElement = (item: IFormSelectValues) => {
    if (item?.disabled) return;
    setValue(name, item.value, item?.description);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    trigger && trigger();
    setPopperOpen(false);
    if (anchorRef.current) {
      (anchorRef.current as HTMLDivElement).focus();
    }
    setDisplayedItems(options);
    setQueryText("");
  };

  const getListHeight = (items: IFormSelectValues[]) => {
    const displayItems = items.slice(0, 5);
    const height = displayItems.reduce((acc, item) => acc + (item?.description ? 68 : 48), 0);
    return height;
  };

  const content = (width: number) => (
    <ClickAwayListener onClickAway={handlePopperClose}>
      <MuiPaper className={theme === "light" ? "paper-light" : ""}>
        <FreeFocusInside>
          {!hideSearch && options.length > 0 && (
            <TextField
              placeholder="Search"
              onChange={handleOnChangeSearch}
              themeType={theme}
              value={queryText}
              autoFocus
            />
          )}
        </FreeFocusInside>
        {displayedItems.length > 0 ? (
          <List
            height={getListHeight(displayedItems)}
            itemSize={(index) => (displayedItems[index].description ? 68 : 48)}
            itemCount={displayedItems.length}
            itemData={{
              items: displayedItems,
              selectItemHandler: setSelectedElement,
            }}
            width={width}
          >
            {Row}
          </List>
        ) : (
          <NoResult className={`${theme === "light" ? "theme-light" : ""}`} style={{ width: `${width}px` }}>
            No result found
          </NoResult>
        )}
      </MuiPaper>
    </ClickAwayListener>
  );

  const inputBoxStyles = {
    ...(isInTable && { marginRight: 0, color: Colour.grey }),
    ...(!isInTable && !value && { marginRight: 20, color: color.gray500 }),
    ...(!isInTable && value && { marginRight: 20 }),
  };

  return (
    <SelectContainer className={`${large ? "large" : ""} ${noMarginBottom ? "no-margin-bottom" : ""}`}>
      {label ? (
        <InputLabel className={`${theme === "light" ? "theme-light" : ""}`}>
          {label}
          {required && "*"}
        </InputLabel>
      ) : undefined}
      {!readOnly ? (
        <InputBox
          button
          ref={anchorRef}
          id={id}
          aria-haspopup="menu"
          aria-controls={popperOpen ? "split-button-menu" : undefined}
          aria-expanded={popperOpen ? "true" : undefined}
          aria-label="when device is locked"
          onClick={handleTogglePopperOpen}
          disableRipple
          tabIndex={tabIndex}
          className={`${large ? "large" : ""} ${theme === "light" ? "btn-light" : ""} ${hasError ? "error" : ""} ${
            isInTable ? "btn-table" : ""
          }`}
          style={inputBoxStyles}
        >
          {value && <>{options.find((item) => item.value === value)?.label}</>}
          {!value && (placeholder || "Select")}
          {popperOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </InputBox>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: 36 }}>
          {value && <>{options.find((item) => item.value === value)?.label}</>}
          {!value && (control?.getValues(name) || placeholder || "Select")}
        </div>
      )}
      {hasError && !hideErrorMessage && (
        <FormHelperText style={{ marginTop: 5 }} error={hasError}>
          {helperText}
        </FormHelperText>
      )}
      {control && (
        <Controller
          as={<input type="text" hidden data-testid={testid} />}
          control={control}
          rules={{ required }}
          name={name}
        />
      )}
      <AutoSizer>
        {({ width }) =>
          disableScroll ? (
            <Popover
              open={popperOpen}
              anchorEl={anchorRef.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              disableEnforceFocus
            >
              <MuiGrow>{content(width)}</MuiGrow>
            </Popover>
          ) : (
            <Popper
              open={popperOpen}
              anchorEl={anchorRef.current}
              transition
              placement="bottom-start"
              style={{
                zIndex: 1500,
              }}
            >
              {({ TransitionProps }) => <MuiGrow {...TransitionProps}>{content(width)}</MuiGrow>}
            </Popper>
          )
        }
      </AutoSizer>
    </SelectContainer>
  );
};

const NoResult = styled("div")({
  width: 280,
  color: "#F1F3F6",
  fontSize: 14,
  padding: "14px 16px",
  minHeight: 36,
  "&.theme-light": {
    color: color.black800,
  },
});

const SelectContainer = styled("div")(() => ({
  marginBottom: "16px",
  "& .MuiFormLabel-root": {
    fontSize: "14px",
    marginBottom: 8,
    fontWeight: 600,
    "&.theme-light": {
      color: `${muiTheme.color.disabledText} !important`,
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    margin: "8px 0px 0",
    "&:empty": {
      display: "none",
    },
  },
  "&.large": {
    marginBottom: 0,
  },
  "&.no-margin-bottom": {
    marginBottom: 0,
  },
}));

const MuiGrow = styled(Grow)({
  zIndex: 10,
});

const MuiPaper = styled(Paper)(() => ({
  background: color.blue800,
  "&.paper-light": {
    background: color.white,
  },
  "& > div": {
    minWidth: "100%",
  },
  boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.09)",
  borderRadius: "4px",
  "& .MuiFormControl-root": {
    marginBottom: "0 !important",
    borderBottom: "2px solid #1a2243",
    "& .input-wrap": {
      borderRadus: 0,
    },
  },
}));

const InputBox = styled(ListItem)({
  background: `${color.white} !important`,
  border: "2px solid",
  fontSize: 14,
  height: 36,
  padding: "10px 15px 9px",
  lineHeight: 1,
  color: color.white,
  borderRadius: 4,
  borderColor: color.white,
  "&.btn-table": {
    padding: "10px 30px 9px 15px",
    justifyContent: "flex-end",
    "& .MuiSvgIcon-root": {
      fill: `${Colour.blueMedium}`,
    },
  },
  "& .MuiSvgIcon-root": {
    position: "absolute",
    right: 1,
    fill: muiTheme.palette.info.main,
  },
  "&[aria-expanded='true'], &.Mui-focusVisible, &:focus": {
    borderColor: `${muiTheme.palette.primary.main} !important`,
  },
  "&.large": {
    fontSize: "22px",
    fontWeight: 700,
    border: "none",
    padding: "0px 24px",
    width: "auto",
  },
  "&.large:hover": {
    background: `${muiTheme.palette.primary.light} !important`,
  },
  "&.btn-light": {
    background: `${color.white} !important`,
    color: color.blue800,
    borderWidth: 1,
    borderColor: muiTheme.palette.grey[500],
    "&[aria-expanded='true'], &.Mui-focusVisible, &:focus": {
      borderColor: `${muiTheme.color.primary.dark} !important`,
    },
    "& .MuiSvgIcon-root": {
      fill: color.blue800,
    },
  },
  "&.error": {
    borderColor: `${muiTheme.palette.error.main} !important`,
  },

  "& input": {
    display: "none",
  },
  "&.large .MuiSvgIcon-root": {
    fontSize: "1.5em",
    position: "relative",
    marginLeft: "0.5em",
  },
});

const IconContainer = styled("div")({
  paddingRight: 15,
});
