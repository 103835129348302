import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { IRoute } from "./RouteInterface";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";
import { UpdateIntercom } from "../components/utility/intercom";

interface IProps {
  routes: IRoute[];
}

export const Router: React.FC<IProps> = ({ routes }) => {
  UpdateIntercom();
  return (
    <Switch>
      {routes.map((route: IRoute) => (
        <RouteWithSubRoutes key={route.path.toString()} {...route} />
      ))}
      <Redirect from="*" to="/signin" />
    </Switch>
  );
};
