import React from "react";
import { styled } from "@material-ui/styles";
import { Colour } from "../../theme/Design";

export const UploadProgressIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle
      opacity="0.2"
      cx="9"
      cy="9"
      r="9"
      transform="matrix(1 0 0 -1 3 21)"
      stroke={Colour.mint20}
      strokeWidth="3"
    />
    <path
      opacity="0.1"
      d="M3.30667 9.67044C3.79858 7.83459 4.85906 6.20159 6.33612 5.00549C7.81317 3.8094 9.63097 3.11161 11.529 3.01214"
      stroke={Colour.mint20}
      strokeWidth="3"
    />
  </svg>
))({});
