import { Tooltip as MuiTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color } from "../theme/Design";

export const Tooltip = withStyles((theme) => ({
  tooltip: {
    padding: 12,
    backgroundColor: color.gray,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 10,
    fontWeight: 500,
    marginTop: 5,
  },
}))(MuiTooltip);
