import { styled } from "@material-ui/styles";

import React from "react";

export const CheckSolid = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.375" width="32" height="32" rx="16" fill={HTMLProps?.style?.fill || "#82CCA0"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0589 8.86523L13.4846 18.4816L9.69106 14.6713L7.375 16.9975L13.4846 23.134L25.375 11.1915L23.0589 8.86523Z"
      fill="white"
    />
  </svg>
))({});
